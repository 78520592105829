*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.\!visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.bottom-3 {
  bottom: .75rem;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.left-6 {
  left: 1.5rem;
}

.bottom-0 {
  bottom: 0;
}

.top-16 {
  top: 4rem;
}

.right-0 {
  right: 0;
}

.top-3 {
  top: .75rem;
}

.left-3 {
  left: .75rem;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-10 {
  z-index: 10;
}

.z-\[100\] {
  z-index: 100;
}

.order-2 {
  order: 2;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clear-both {
  clear: both;
}

.m-2 {
  margin: .5rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-1 {
  margin: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-0 {
  margin-left: 0;
}

.mt-10 {
  margin-top: 2.5rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-3 {
  margin-right: .75rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-24 {
  margin-top: 6rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-full {
  height: 100%;
}

.h-5 {
  height: 1.25rem;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-8 {
  height: 2rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-24 {
  height: 6rem;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-32 {
  height: 8rem;
}

.h-7 {
  height: 1.75rem;
}

.h-20 {
  height: 5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-3 {
  height: .75rem;
}

.max-h-\[50rem\] {
  max-height: 50rem;
}

.min-h-full {
  min-height: 100%;
}

.w-full {
  width: 100%;
}

.w-8 {
  width: 2rem;
}

.w-6 {
  width: 1.5rem;
}

.w-4 {
  width: 1rem;
}

.w-20 {
  width: 5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-64 {
  width: 16rem;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-11 {
  width: 2.75rem;
}

.w-3\/4 {
  width: 75%;
}

.w-48 {
  width: 12rem;
}

.w-28 {
  width: 7rem;
}

.w-36 {
  width: 9rem;
}

.w-16 {
  width: 4rem;
}

.w-auto {
  width: auto;
}

.w-2 {
  width: .5rem;
}

.w-56 {
  width: 14rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-1\/2 {
  width: 50%;
}

.w-14 {
  width: 3.5rem;
}

.w-12 {
  width: 3rem;
}

.w-screen {
  width: 100vw;
}

.w-0 {
  width: 0;
}

.w-10 {
  width: 2.5rem;
}

.w-3 {
  width: .75rem;
}

.w-1\/3 {
  width: 33.3333%;
}

.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-16 {
  gap: 4rem;
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)) );
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-2xl {
  border-radius: 1rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-double {
  border-style: double;
}

.border-transparent {
  border-color: #0000;
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 224 71 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 63 18 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-black {
  --tw-gradient-from: #000;
  --tw-gradient-to: #0000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green-700 {
  --tw-gradient-from: #15803d;
  --tw-gradient-to: #15803d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red-700 {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-to: #b91c1c00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-to: #0000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-white {
  --tw-gradient-to: #fff;
}

.bg-cover {
  background-size: cover;
}

.bg-top {
  background-position: top;
}

.fill-blue-200 {
  fill: #bfdbfe;
}

.fill-green-200 {
  fill: #bbf7d0;
}

.fill-yellow-200 {
  fill: #fef08a;
}

.fill-red-200 {
  fill: #fecaca;
}

.stroke-green-600 {
  stroke: #16a34a;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-8 {
  padding: 2rem;
}

.p-1 {
  padding: .25rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-3 {
  padding: .75rem;
}

.p-10 {
  padding: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-0 {
  padding-right: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-\[2px\] {
  padding-top: 2px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-start {
  text-align: start;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.font-semibold {
  font-weight: 600;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-black {
  font-weight: 900;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-none {
  line-height: 1;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.text-opacity-75 {
  --tw-text-opacity: .75;
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(199 210 254 / var(--tw-placeholder-opacity));
}

.opacity-80 {
  opacity: .8;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.opacity-75 {
  opacity: .75;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

body {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
}

#not-found {
  background-image: url("404-background.66db7c6f.avif");
}

.focus-within\:text-gray-400:focus-within {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:border-indigo-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.hover\:bg-slate-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.hover\:text-cyan-600:hover {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:text-cyan-500:hover {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.hover\:text-slate-200:hover {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-slate-100:hover {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

.focus\:ring-slate-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-indigo-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.disabled\:bg-slate-600:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.group:hover .group-hover\:flex {
  display: flex;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.group:hover .group-hover\:border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52 / var(--tw-border-opacity));
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:order-3 {
    order: 3;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:float-right {
    float: right;
  }

  .sm\:float-left {
    float: left;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-px {
    margin-top: 1px;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .sm\:-ml-0 {
    margin-left: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-4 {
    height: 1rem;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }

  .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .sm\:border-l {
    border-left-width: 1px;
  }

  .sm\:border-t {
    border-top-width: 1px;
  }

  .sm\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .sm\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .sm\:bg-center {
    background-position: center;
  }

  .sm\:p-3 {
    padding: .75rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:pt-2 {
    padding-top: .5rem;
  }

  .sm\:pr-2 {
    padding-right: .5rem;
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:pt-20 {
    padding-top: 5rem;
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }

  .sm\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }
}

@media (min-width: 768px) {
  .md\:order-2 {
    order: 2;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:place-items-center {
    place-items: center;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:block {
    display: block;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:w-96 {
    width: 24rem;
  }

  .xl\:w-64 {
    width: 16rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:hidden {
    display: none;
  }
}

.PinturaPanel.PinturaSliderPanel {
  border-radius: var(--border-radius-round);
}

.PinturaPercentageLabel {
  font-variant-numeric: tabular-nums;
  min-width: 3em;
  text-align: center;
}

.PinturaSliderPanel .PinturaSlider {
  width: 10em;
  --knob-size: .75em;
  --knob-margin-left: .3125em;
  --knob-margin-right: .3125em;
}

.PinturaSliderPanel .PinturaSliderTrack {
  background-color: var(--color-foreground-10);
}

.PinturaSliderPanel .PinturaSliderKnob {
  background-color: var(--color-foreground-100);
  box-shadow: 0 .125em .25em #00000059, 0 -.125em .25em #0000001a;
}

.PinturaRadioGroup .PinturaRadioGroupOptions {
  flex-direction: column;
  display: flex;
}

.PinturaRadioGroup .PinturaRadioGroupOptionGroup + .PinturaRadioGroupOptionGroup {
  margin-top: .5em;
}

.PinturaRadioGroup .PinturaRadioGroupOption[data-hidden="true"] {
  display: none;
}

.PinturaRadioGroup[data-layout="row"] .PinturaRadioGroupOptions {
  flex-direction: row;
}

.PinturaRadioGroup label {
  -webkit-tap-highlight-color: transparent;
}

.PinturaUtilPanel[data-util="redact"] .PinturaUtilFooter {
  padding: 0;
}

.PinturaUtilPanel[data-util="redact"] .PinturaUtilFooter > div {
  height: 1px;
}

.pintura-editor, pintura-editor {
  --color-primary: #ffd843;
  --color-primary-dark: #ffc343;
  --color-primary-text: #000;
  --color-secondary: #03a9f4;
  --color-secondary-dark: #046bbf;
  --color-focus: 4, 107, 191;
  --color-focus-100: rgba(var(--color-focus), 1);
  --color-focus-50: rgba(var(--color-focus), .5);
  --color-focus-25: rgba(var(--color-focus), .25);
  --color-error: 255, 87, 34;
  --color-error-100: rgb(var(--color-error));
  --color-error-75: rgba(var(--color-error), .75);
  --color-error-50: rgba(var(--color-error), .5);
  --color-error-25: rgba(var(--color-error), .25);
  --color-error-10: rgba(var(--color-error), .1);
  --color-foreground: 0, 0, 0;
  --color-background: 255, 255, 255;
  --color-preview-outline: var(--color-foreground);
  --color-transition-duration: 250ms;
  --button-cursor: pointer;
  --font-size: 16px;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --backdrop-filter-bright: brightness(110%) saturate(180%) blur(10px);
  --backdrop-filter-dark: brightness(90%) saturate(180%) blur(10px);
  --border-radius-round: 9999em;
  --border-radius: .625em;
  --filter-disabled: grayscale(95%) opacity(40%);
  --editor-inset-top: 0px;
  --editor-inset-bottom: 0px;
  --safe-area-inset-top: 0px;
  --safe-area-inset-bottom: 0px;
}

.pintura-editor, .pintura-editor-panel, pintura-editor {
  --color-foreground-100: rgba(var(--color-foreground), 1);
  --color-foreground-95: rgba(var(--color-foreground), .95);
  --color-foreground-90: rgba(var(--color-foreground), .9);
  --color-foreground-80: rgba(var(--color-foreground), .8);
  --color-foreground-70: rgba(var(--color-foreground), .7);
  --color-foreground-60: rgba(var(--color-foreground), .6);
  --color-foreground-50: rgba(var(--color-foreground), .5);
  --color-foreground-40: rgba(var(--color-foreground), .4);
  --color-foreground-30: rgba(var(--color-foreground), .3);
  --color-foreground-20: rgba(var(--color-foreground), .25);
  --color-foreground-15: rgba(var(--color-foreground), .2);
  --color-foreground-10: rgba(var(--color-foreground), .15);
  --color-foreground-5: rgba(var(--color-foreground), .075);
  --color-foreground-3: rgba(var(--color-foreground), .05);
  --color-foreground-1: rgba(var(--color-foreground), .02);
  --color-foreground-0: rgba(var(--color-foreground), 0);
  --color-background-100: rgba(var(--color-background), 1);
  --color-background-95: rgba(var(--color-background), .95);
  --color-background-90: rgba(var(--color-background), .9);
  --color-background-80: rgba(var(--color-background), .8);
  --color-background-70: rgba(var(--color-background), .7);
  --color-background-60: rgba(var(--color-background), .6);
  --color-background-50: rgba(var(--color-background), .5);
  --color-background-40: rgba(var(--color-background), .4);
  --color-background-30: rgba(var(--color-background), .3);
  --color-background-20: rgba(var(--color-background), .2);
  --color-background-15: rgba(var(--color-background), .15);
  --color-background-10: rgba(var(--color-background), .1);
  --color-background-5: rgba(var(--color-background), .05);
  --color-background-3: rgba(var(--color-background), .03);
  --color-background-1: rgba(var(--color-background), .01);
  --color-background-0: rgba(var(--color-background), 0);
}

.pintura-editor, .PinturaRootWrapper, pintura-editor {
  display: block;
}

.PinturaRootWrapper {
  height: 100%;
}

.PinturaScrollableContent {
  max-height: 80vh;
  scrollbar-color: var(--color-foreground-30) transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
}

.PinturaScrollableContent::-webkit-scrollbar {
  width: 1em;
  cursor: pointer;
}

.PinturaScrollableContent::-webkit-scrollbar-track {
  background: none;
}

.PinturaScrollableContent::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-clip: padding-box;
  background-color: var(--color-foreground-30);
  border: .3125em solid #0000;
  border-radius: 9999em;
}

.PinturaRoot[data-env~="is-animated"] {
  --transition-duration-multiplier: 1;
}

.PinturaRoot {
  --nav-group-margin: .5em;
  --editor-calculated-inset-top: calc(var(--safe-area-inset-top)  + var(--editor-inset-top));
  --editor-calculated-inset-bottom: calc(var(--safe-area-inset-bottom)  + var(--editor-inset-bottom));
  --transition-duration-10: calc(var(--transition-duration-multiplier, 0) * .1s);
  --transition-duration-25: calc(var(--transition-duration-multiplier, 0) * .25s);
  --transition-duration-50: calc(var(--transition-duration-multiplier, 0) * .5s);
  box-sizing: border-box;
  width: var(--editor-width, 100%);
  height: var(--editor-height, 100%);
  padding-top: var(--editor-calculated-inset-top);
  padding-bottom: var(--editor-calculated-inset-bottom);
  max-width: var(--editor-max-width, var(--editor-max-width-default, none));
  max-height: var(--editor-max-height, var(--editor-max-height-default, none));
  contain: strict;
  -webkit-user-select: none;
  user-select: none;
  line-height: normal;
  font-size: var(--font-size);
  font-family: var(--font-family);
  touch-action: manipulation;
  text-align: left;
  text-transform: none;
  text-rendering: optimizelegibility;
  direction: ltr;
  color: var(--color-foreground-90);
  background-color: var(--color-background-100);
  outline-color: rgba(var(--color-preview-outline), 1);
  transition: background-color 1ms, outline-color 1ms, color var(--transition-duration-10) ease-in-out;
  font-weight: 450;
  display: grid;
  position: relative;
  overflow: hidden;
}

.PinturaRoot * {
  box-sizing: content-box;
  word-wrap: normal;
}

.PinturaRoot button, .PinturaRoot fieldset, .PinturaRoot input, .PinturaRoot legend {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  width: auto;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.PinturaRoot button[disabled] {
  pointer-events: none;
}

.PinturaRoot canvas {
  display: block;
}

.PinturaRoot svg {
  width: auto;
  height: auto;
  display: inline-block;
}

.PinturaRoot p {
  margin: 0;
}

.PinturaRoot li, .PinturaRoot ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.PinturaRoot a {
  color: inherit;
  text-decoration: none;
}

.PinturaRoot .implicit {
  clip: rect(0 0 0 0);
  -webkit-clip-path: polygon(0 0, 0 0, 0 0);
  clip-path: polygon(0 0, 0 0, 0 0);
  height: 1px;
  width: 1px;
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.PinturaRoot .PinturaFixedWidthCharacters {
  font-variant: tabular-nums;
}

.PinturaRoot .PinturaStage {
  flex: 1;
  align-self: stretch;
  display: flex;
}

.PinturaRoot .PinturaStage[tabindex="-1"] {
  outline: none;
}

.PinturaRoot .PinturaRootPortal {
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaRoot > .PinturaCanvas {
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaRoot > .PinturaCanvas:after {
  content: "";
  pointer-events: none;
  background-repeat: no-repeat;
  position: absolute;
  inset: 0;
}

.PinturaRoot > .PinturaStatus {
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-90);
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaRoot > .PinturaStatus > p {
  white-space: nowrap;
  transition: opacity var(--transition-duration-50) ease-out;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 50%;
}

.PinturaRoot .PinturaStatusMessage {
  font-size: .875em;
  position: absolute;
}

.PinturaRoot .PinturaStatusAside {
  position: absolute;
  left: 0;
}

.PinturaRoot .PinturaStatusAside svg {
  width: 1.25em;
  height: 1.25em;
}

.PinturaRoot .PinturaStatusAside .PinturaProgressIndicator {
  margin-left: .5em;
}

.PinturaRoot .PinturaStatusAside .PinturaButton {
  border-radius: var(--border-radius-round);
  background-color: var(--color-foreground-5);
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out;
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  backdrop-filter: var(--backdrop-filter-dark);
  outline: #0000;
  margin-left: .75em;
  padding: .25em;
  font-size: .875em;
}

.PinturaRoot .PinturaStatusAside .PinturaButton:not([disabled])[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot .PinturaStatusAside .PinturaButton:not([disabled]):hover {
  background-color: var(--color-foreground-10);
}

.PinturaRoot .PinturaStatusIcon svg {
  margin-top: .25em;
  margin-left: .5em;
  font-size: .875em;
}

.PinturaRoot > .PinturaNav {
  z-index: 3;
  position: relative;
}

.PinturaRoot > .PinturaNav:empty {
  display: none !important;
}

.PinturaRoot > .PinturaNav .PinturaButton {
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out;
  outline: #0000;
}

.PinturaRoot > .PinturaNav .PinturaButton svg {
  width: 1em;
  transition: opacity var(--transition-duration-10) ease-out;
}

.PinturaRoot > .PinturaNav .PinturaButton > span {
  justify-content: center;
}

.PinturaRoot > .PinturaNav .PinturaButtonIconOnly .PinturaButtonInner, .PinturaRoot > .PinturaNav .PinturaDropdownIconOnly .PinturaButtonInner {
  width: 1.75em;
}

.PinturaRoot > .PinturaNav .PinturaButton .PinturaButtonInner {
  height: 1.75em;
}

.PinturaRoot > .PinturaNav .PinturaButton:not(.PinturaButtonIconOnly):not(.PinturaDropdownIconOnly) {
  padding-left: .75em;
  padding-right: .75em;
}

.PinturaRoot > .PinturaNav .PinturaButton:not(.PinturaButtonIconOnly):not(.PinturaDropdownIconOnly) .PinturaButtonLabel {
  font-size: .75em;
  line-height: 2;
}

.PinturaRoot > .PinturaNav .PinturaButton:not(.PinturaButtonIconOnly):not(.PinturaDropdownIconOnly):not(.PinturaDropdownButton) {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaRoot > .PinturaNav .PinturaButton:only-of-type {
  border-radius: var(--border-radius-round);
}

.PinturaRoot > .PinturaNav .PinturaButton:only-of-type + * {
  margin-left: .5em;
}

.PinturaRoot > .PinturaNav .PinturaButton[disabled] svg {
  opacity: .25;
}

.PinturaRoot > .PinturaNav .PinturaButton:not(.PinturaButtonExport):not([disabled])[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot > .PinturaNav .PinturaButton:not(.PinturaButtonExport):not([disabled]):hover {
  background-color: var(--color-foreground-10);
}

.PinturaRoot .PinturaButtonExport {
  color: var(--color-primary-text);
  background-color: var(--color-primary);
}

.PinturaRoot .PinturaButtonExport:hover {
  background-color: var(--color-primary-dark);
}

.PinturaRoot .PinturaButtonExport[data-focus-visible] {
  color: var(--color-primary-text);
  background-color: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5), 0 0 0 3px var(--color-focus-50);
  background-color: var(--color-primary-dark);
}

.PinturaRoot .PinturaButtonExport:not(.PinturaButtonIconOnly) {
  flex: 1;
  padding: 0 .75em;
}

.PinturaRoot > .PinturaNavMain {
  display: flex;
  overflow: hidden;
}

.PinturaRoot > .PinturaNavMain [aria-selected="true"] button {
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  backdrop-filter: var(--backdrop-filter-dark);
  background-color: var(--color-foreground-10);
}

.PinturaRoot > .PinturaNavMain button svg {
  width: 1.5em;
  align-self: center;
}

.PinturaRoot > .PinturaNavMain button span, .PinturaRoot > .PinturaNavMain button svg {
  pointer-events: none;
}

.PinturaRoot > .PinturaNavMain button svg + span {
  margin-top: .75em;
}

.PinturaRoot > .PinturaNavMain button span {
  width: calc(100% - 1em);
  text-overflow: ellipsis;
  width: inherit;
  max-width: calc(100% - 1em);
  font-size: .875em;
  display: block;
  overflow: hidden;
}

.PinturaRoot > .PinturaNavMain button {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out, box-shadow var(--transition-duration-10) ease-out;
}

.PinturaRoot > .PinturaNavMain button:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}

.PinturaRoot > .PinturaNavMain button[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot > .PinturaNavTools {
  max-width: 100%;
  box-sizing: border-box;
  pointer-events: none;
}

.PinturaRoot > .PinturaNavTools:empty {
  display: none;
}

.PinturaRoot > .PinturaNavTools, .PinturaRoot > .PinturaNavTools .PinturaNavGroup, .PinturaRoot > .PinturaNavTools .PinturaNavSet {
  justify-content: center;
  align-items: center;
  display: flex;
}

.PinturaRoot > .PinturaNavTools .PinturaButton, .PinturaRoot > .PinturaNavTools .PinturaNavSet {
  pointer-events: all;
}

.PinturaRoot > .PinturaNavTools .PinturaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot .PinturaNavGroup {
  margin: 0 var(--nav-group-margin);
}

.PinturaRoot .PinturaNavGroup > * {
  border-radius: var(--border-radius-round);
}

.PinturaRoot .PinturaNavGroup > * + * {
  margin-left: .5em;
}

.PinturaRoot .PinturaNavGroup:first-of-type {
  margin-left: 0;
  margin-right: auto;
}

.PinturaRoot .PinturaNavGroup:last-of-type {
  margin-left: auto;
  margin-right: 0;
}

.PinturaRoot .PinturaNavSet {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaRoot .PinturaNavSet > :not(:only-child) {
  border: 1px solid var(--color-foreground-3);
  border-top: 0;
  border-bottom: 0;
}

.PinturaRoot .PinturaNavSet > :first-child:not(:only-child) {
  border-left: 0;
}

.PinturaRoot .PinturaNavSet > :last-child:not(:only-child) {
  border-right: 0;
}

.PinturaRoot .PinturaNavSet > * + * {
  margin-left: -1px;
}

.PinturaRoot .PinturaNavSet > .PinturaButton:hover, .PinturaRoot .PinturaNavSet > .PinturaButton[data-focus-visible] {
  z-index: 1;
  position: relative;
}

.PinturaRoot .PinturaNavSet > :only-child {
  border-radius: var(--border-radius-round);
}

.PinturaRoot .PinturaNavSet > :first-child:not(:only-child) .PinturaButtonInner {
  padding: 0 .125em 0 .25em;
}

.PinturaRoot .PinturaNavSet > :first-child:not(:only-child) {
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
}

.PinturaRoot .PinturaNavSet > :last-child:not(:only-child) .PinturaButtonInner {
  padding: 0 .25em 0 .125em;
}

.PinturaRoot .PinturaNavSet > :last-child:not(:only-child) {
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
}

.PinturaRoot[data-env~="overlay"] .PinturaNavSet {
  background-color: var(--color-background-50);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  box-shadow: inset 0 0 0 1px var(--color-background-15), 0 .0625em .125em #00000040, 0 .125em .35em #00000059;
}

.PinturaRoot[data-env~="overlay"] .PinturaNavSet .PinturaButton:not(.PinturaButtonExport):not([disabled]):hover, .PinturaRoot[data-env~="overlay"] .PinturaNavSet > * {
  border-color: var(--color-background-15);
}

.PinturaRoot[data-env~="overlay"] .PinturaNavGroup > .PinturaButton {
  box-shadow: 0 .0625em .125em #00000040, 0 .125em .35em #00000059;
}

.PinturaRoot[data-env~="overlay"] .PinturaNavGroup .PinturaButtonExport {
  box-shadow: inset 0 0 .125em #00000040, 0 .0625em .125em #00000040, 0 .125em .35em #00000059;
}

.PinturaRoot .PinturaNavGroupFloat {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
}

.PinturaRoot .PinturaNavGroupFloat > * {
  pointer-events: all;
}

.PinturaRoot .PinturaUtilFooter .PinturaScrollable > div {
  padding-left: 1em;
  padding-right: 1em;
}

.PinturaRoot > .PinturaMain {
  max-width: 100vw;
}

.PinturaRoot:not([data-env~="has-toolbar"]) .PinturaUtilHeader {
  margin-bottom: -1em;
  padding-top: 0;
  padding-bottom: 1em;
}

.PinturaRoot[data-env~="landscape"] {
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  grid-template-columns: auto;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavTools {
  z-index: 3;
  grid-area: 1 / 1;
  padding: 1em 1em 0;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain {
  left: 1em;
  top: calc(1em + var(--editor-calculated-inset-top));
  bottom: calc(1em + var(--editor-calculated-inset-bottom));
  justify-content: flex-end;
  align-items: center;
  position: absolute;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain .PinturaTabList {
  flex-direction: column;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain .PinturaTabList li {
  display: flex;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain .PinturaTabList li + li {
  margin: .5em 0 0;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain button {
  width: 4em;
  height: 4em;
  border-radius: var(--border-radius);
  flex: 1;
  justify-content: center;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain button span {
  margin-top: .5em;
  font-size: .6875em;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain button svg {
  width: 1em;
  margin-top: .25em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-toolbar"] > .PinturaNavMain {
  top: calc(4em + var(--editor-calculated-inset-top));
  bottom: calc(4em + var(--editor-calculated-inset-bottom));
}

.PinturaRoot[data-env~="landscape"][data-env~="has-limited-space"] > .PinturaNavMain {
  bottom: calc(1em + var(--editor-calculated-inset-bottom));
  align-items: flex-start;
}

.PinturaRoot[data-env~="landscape"] > .PinturaMain {
  grid-area: 2 / 1;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-toolbar-preference-bottom"] {
  grid-template-rows: auto -webkit-min-content;
  grid-template-rows: auto min-content;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-toolbar-preference-bottom"] > .PinturaNavTools {
  grid-row: 2;
  padding-top: 0;
  padding-bottom: 1em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-toolbar-preference-bottom"] > .PinturaMain {
  grid-row: 1;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"] {
  grid-template-columns: 6em auto;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"]:not([data-env~="narrow"]) .PinturaNavGroupFloat {
  margin: 0 0 0 7em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"] > .PinturaNavTools {
  grid-column: 1 / span 2;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"] > .PinturaMain {
  grid-column: 2;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"]:not([data-env~="has-navigation-preference-right"]) .PinturaUtilMain {
  padding-left: 0;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"]:not([data-env~="has-navigation-preference-right"]) .PinturaUtilFooter {
  padding-right: 1em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"] {
  grid-template-columns: auto 6em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"]:not([data-env~="narrow"]) .PinturaNavGroupFloat {
  margin: 0 7em 0 0;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"] > .PinturaNavMain {
  left: auto;
  right: 1em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"] > .PinturaMain {
  grid-column: 1;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"] .PinturaUtilMain {
  padding-right: 0;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"] .PinturaUtilFooter {
  padding-left: 1em;
}

.PinturaRoot[data-env~="portrait"] {
  grid-template-rows: -webkit-min-content auto -webkit-min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: auto;
}

.PinturaRoot[data-env~="portrait"] > * {
  grid-column: 1;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavTools {
  grid-row: 1;
  padding: .75em .75em 0;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain {
  grid-row: 3;
  justify-content: center;
  margin-bottom: .75em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain .PinturaTabList {
  padding: 0 .875em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain li + li {
  margin-left: .5em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain[data-state~="overflows"] {
  overflow: visible;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain button {
  width: 4em;
  height: 4em;
  border-radius: var(--border-radius);
  flex: 1;
  justify-content: center;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain button span {
  margin-top: .5em;
  font-size: .6875em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain button svg {
  width: 1em;
  margin-top: .25em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaMain {
  grid-area: 2 / 1;
}

.PinturaRoot[data-env~="portrait"][data-env~="has-toolbar-preference-bottom"] {
  grid-template-rows: auto -webkit-min-content -webkit-min-content;
  grid-template-rows: auto min-content min-content;
}

.PinturaRoot[data-env~="portrait"][data-env~="has-toolbar-preference-bottom"] > .PinturaNavTools {
  grid-row: 3;
  padding: 0 .75em .75em;
}

.PinturaRoot[data-env~="portrait"][data-env~="has-toolbar-preference-bottom"] > .PinturaNavMain {
  grid-row: 2;
}

.PinturaRoot[data-env~="portrait"][data-env~="has-toolbar-preference-bottom"] > .PinturaMain {
  grid-row: 1;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"] {
  grid-template-rows: -webkit-min-content -webkit-min-content auto;
  grid-template-rows: min-content min-content auto;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"] > .PinturaMain {
  grid-row: 3;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"] > .PinturaNavMain {
  grid-row: 2;
  margin-top: .75em;
  margin-bottom: 0;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"][data-env~="has-toolbar-preference-bottom"] {
  grid-template-rows: -webkit-min-content auto -webkit-min-content;
  grid-template-rows: min-content auto min-content;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"][data-env~="has-toolbar-preference-bottom"] > .PinturaNavMain {
  grid-row: 1;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"][data-env~="has-toolbar-preference-bottom"] > .PinturaMain {
  grid-row: 2;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"][data-env~="has-toolbar-preference-bottom"] > .PinturaNavTools {
  grid-row: 3;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"] > .PinturaNavMain button {
  width: auto;
  height: auto;
  border-radius: var(--border-radius);
  width: 6em;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding: .625em 0;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"] > .PinturaNavMain button span {
  text-align: left;
  margin: 0 .625em 0 0;
  font-size: .6875em;
  line-height: 1.2;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"] > .PinturaNavMain button svg {
  min-width: 1em;
  margin: 0 .5em 0 .625em;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"][data-env~="has-navigation"] {
  grid-template-columns: 8em auto;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"] {
  grid-template-columns: auto 8em;
}

.PinturaRoot[data-env~="narrow"] .PinturaNavGroupFloat {
  margin: 0;
  position: static;
}

.PinturaRoot[data-env~="portrait"][data-env~="is-compact"] > .PinturaNavMain button {
  width: 4em;
  height: 4em;
  border-radius: 9999em;
  justify-content: center;
  padding: 0;
  font-size: .625em;
}

.PinturaRoot[data-env~="portrait"][data-env~="is-compact"] > .PinturaNavMain button span {
  visibility: hidden;
  margin: 0;
  font-size: 0;
}

.PinturaRoot[data-env~="portrait"][data-env~="is-compact"] > .PinturaNavMain button svg {
  width: 1.6em;
  margin-top: 0;
}

.PinturaRoot[data-env~="portrait"][data-env~="is-compact"] > .PinturaNavMain button svg [stroke-width] {
  stroke-width: .15em;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"] {
  --nav-group-margin: .25em;
  grid-template-rows: -webkit-min-content auto -webkit-min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: auto;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"] > * {
  grid-column: 1;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"] .PinturaUtilFooter .PinturaControlList .PinturaControlListOption span, .PinturaRoot[data-env~="portrait"][data-env~="narrow"] .PinturaUtilFooter .PinturaControlList [role="tab"] span {
  font-size: .6875em;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"] .PinturaToolbar .PinturaToolbarInner > * {
  margin: 0 .25em;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"][data-env~="has-toolbar-preference-bottom"] {
  grid-template-rows: auto -webkit-min-content -webkit-min-content;
  grid-template-rows: auto min-content min-content;
}

.PinturaRoot[data-env~="overlay"] {
  grid-template-rows: auto !important;
  grid-template-columns: auto !important;
}

.PinturaRoot[data-env~="overlay"] .PinturaNav {
  z-index: 3;
}

.PinturaRoot[data-env~="overlay"] .PinturaMain {
  grid-area: 1 / 1;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilMain {
  z-index: -1;
  padding: 0;
  position: absolute;
  inset: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter, .PinturaRoot[data-env~="overlay"] .PinturaUtilHeader {
  position: absolute;
  left: 0;
  right: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilHeader {
  top: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter {
  bottom: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaNavMain {
  background: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaNavTools {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.PinturaRoot[data-env~="overlay"][data-env~="has-controlgroups-preference-top"] .PinturaUtilFooter {
  top: 0;
  bottom: auto;
}

.PinturaRoot[data-env~="overlay"][data-env~="has-toolbar-preference-bottom"] .PinturaNavTools {
  top: auto;
  bottom: 0;
}

.PinturaRoot[data-env~="has-swipe-navigation"] .PinturaUtilMain {
  padding-left: 2em;
  padding-right: 2em;
}

.PinturaRoot[data-env~="has-swipe-navigation"][data-env~="landscape"] .PinturaUtilMain[data-env~="has-navigation"] {
  padding-left: 0;
  padding-right: 2em;
}

.PinturaRoot[data-env~="has-swipe-navigation"][data-env~="landscape"] .PinturaUtilMain[data-env~="has-navigation"][data-env~="has-navigation-preference-right"] {
  padding-left: 2em;
  padding-right: 0;
}

.PinturaRoot[data-env*="is-disabled"], .PinturaRoot[data-env*="is-disabled"] * {
  pointer-events: none !important;
}

.PinturaRoot[data-env*="is-disabled"] {
  --color-primary: #b4b4b4;
  --color-primary-dark: #a0a0a0;
  --color-primary-text: #000;
  --color-secondary: #969696;
  --color-secondary-dark: #646464;
}

.PinturaRoot[data-env*="is-disabled"] .PinturaCanvas, .PinturaRoot[data-env*="is-disabled"] .PinturaColorPickerButton {
  filter: grayscale();
}

.PinturaRoot .PinturaEditorOverlay {
  z-index: 3;
  background: var(--color-background-50);
  pointer-events: none;
  position: absolute;
  inset: 0;
}

@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) {
    .PinturaNav {
      will-change: transform;
    }
  }
}

.PinturaOptionsList, .PinturaOptionsListWrapper {
  border-radius: inherit;
}

.PinturaOptionsList .PinturaListOption {
  transition: background-color var(--transition-duration-10);
  border-radius: inherit;
}

.PinturaOptionsList .PinturaListOption label {
  border-radius: inherit;
  white-space: nowrap;
  margin: 0;
  display: block;
}

.PinturaOptionsList .PinturaListOption svg {
  min-width: 1.5em;
  margin-left: -.5em;
  margin-right: .25em;
}

.PinturaOptionsList .PinturaListOption [slot] {
  align-items: center;
  padding: .325em .75em;
  display: flex;
}

.PinturaOptionsList .PinturaDropdownOptionLabel {
  flex-direction: column;
  margin-left: -.325em;
  padding: 0 .325em;
  font-size: .75em;
  line-height: 1.75;
  display: inline-flex;
}

.PinturaOptionsList .PinturaDropdownOptionSublabel {
  opacity: .5;
  margin-top: -.5em;
  font-size: .75em;
}

.PinturaOptionsList .PinturaListOptionGroup > span {
  text-transform: uppercase;
  color: var(--color-foreground-50);
  padding: .75em 1em;
  font-size: .75em;
  display: block;
}

.PinturaOptionsList [data-disabled="true"] {
  color: var(--color-foreground-50);
}

.PinturaOptionsList [data-selected="false"]:not([data-disabled="true"]):hover {
  background-color: var(--color-foreground-10);
}

.PinturaOptionsList [data-selected="true"] {
  color: var(--color-foreground-100);
  background-color: var(--color-foreground-20);
}

.PinturaOptionsList [data-focus-visible] + label .PinturaDropdownOptionLabel {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  border-radius: .25em;
}

.PinturaListOption label {
  margin: 0;
}

.PinturaColorPickerPanel {
  max-width: 14em;
  touch-action: none;
  padding: .5em;
  overflow: visible;
}

.PinturaColorPickerPanel .PinturaColorPickerButton {
  outline: #0000;
}

.PinturaColorPickerPanel .PinturaColorPickerButton[data-focus-visible] {
  box-shadow: inset 0 0 0 1px var(--color-foreground-70);
}

.PinturaColorPickerPanel .PinturaColorPreview {
  width: 1.25em;
  height: 1.25em;
  border-radius: .25em;
}

.PinturaColorPickerPanel .PinturaColorPreview span {
  box-shadow: inset 0 0 0 1px var(--color-foreground-20);
  border-radius: .2em;
}

.PinturaColorPickerPanel .PinturaColorPresets legend {
  color: var(--color-foreground-50);
  margin: 0 0 .25em .3125em;
  font-size: .75em;
}

.PinturaColorPickerPanel .PinturaColorPresets:only-child legend {
  display: none;
}

.PinturaColorPickerPanel .PinturaColorPresetsGrid .PinturaRadioGroupOptions {
  flex-flow: wrap;
  display: flex;
}

.PinturaColorPickerPanel .PinturaColorPresetsGrid .PinturaListOption {
  padding: .25em;
  display: block;
}

.PinturaColorPickerPanel .PinturaColorPresetsGrid .PinturaListOption label, .PinturaColorPickerPanel .PinturaColorPresetsGrid .PinturaListOption label span {
  cursor: var(--button-cursor);
  display: block;
}

.PinturaColorPickerPanel .PinturaColorPresetsGrid input[data-focus-visible] + label .PinturaColorPreview:after {
  border-radius: inherit;
  pointer-events: none;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  position: absolute;
  inset: -.3125em;
}

.PinturaColorPickerPanel .PinturaSlider {
  margin-left: -.325em;
  margin-right: -.325em;
}

.PinturaColorPickerPanel .PinturaSlider > .PinturaSliderControl {
  z-index: 2;
  position: relative;
}

.PinturaColorPickerPanel .PinturaPicker {
  width: 100%;
}

.PinturaColorPickerPanel .PinturaPicker + .PinturaColorPresets {
  margin-top: .5em;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaPickerKnob, .PinturaColorPickerPanel .PinturaPicker .PinturaSliderKnob {
  border-radius: 9999em;
  box-shadow: inset 0 .06125em .06125em #0000001a, 0 0 0 .06125em #fff, 0 .1875em .25em #00000059, 0 -.125em .25em #0000001a;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaPickerKnob {
  pointer-events: none;
  z-index: 1;
  width: 1.25em;
  height: 1.25em;
  visibility: visible;
  outline: none;
  margin-top: -.625em;
  margin-left: -.625em;
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaPickerKnob[data-focus-visible]:after {
  border-radius: inherit;
  pointer-events: none;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  position: absolute;
  inset: -.3125em;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaPickerKnobController {
  visibility: hidden;
  position: absolute;
  inset: 0;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaSaturationPicker {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  height: 7em;
  background-image: linear-gradient(#0000, #000), linear-gradient(90deg, #fff, #0000);
  margin: -.5em -.5em .5em;
  position: relative;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaHuePicker .PinturaSliderTrack {
  background-image: linear-gradient(90deg, red, #ff0, #0f0, #0ff, #00f, #f0f, red);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaSliderTrack {
  box-shadow: inset 0 0 1px var(--color-foreground-50);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaOpacityPicker .PinturaSliderKnob:before, .PinturaColorPickerPanel .PinturaPicker .PinturaOpacityPicker .PinturaSliderTrack:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: inherit;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h4v4H0zm4 4h4v4H4z' fill='%23E5E5E5'/%3E%3C/svg%3E");
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaOpacityPicker .PinturaSliderKnob:before {
  background-position: calc(var(--slider-position) * -2px) 0;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaOpacityPicker {
  margin-top: .5em;
}

.PinturaUtilPanel[data-util="filter"] .PinturaUtilFooter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.PinturaUtilPanel[data-util="filter"] .PinturaScrollable {
  width: 40em;
}

.PinturaUtilPanel[data-util="filter"] [data-layout="row"] > .PinturaRadioGroupOptions > li {
  margin: 0 .5em;
}

.PinturaUtilPanel[data-util="filter"] [data-layout="row"] > .PinturaRadioGroupOptions > li:first-child {
  margin-left: 0;
}

.PinturaUtilPanel[data-util="filter"] [data-layout="row"] > .PinturaRadioGroupOptions > li:last-child {
  margin-right: 0;
}

.PinturaUtilPanel[data-util="filter"] .PinturaRadioGroupOptionGroupLabel {
  width: 1px;
  height: 1px;
  pointer-events: none;
  font-size: 0;
  position: absolute;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterPreview {
  border-radius: 0;
  transition: border-radius 1ms;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterOption {
  border-radius: 0;
}

.PinturaUtilPanel[data-util="filter"] .PinturaRadioGroupOptions .PinturaRadioGroupOption:first-of-type .PinturaFilterPreview {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="filter"] .PinturaRadioGroupOptions .PinturaRadioGroupOption:first-of-type .PinturaFilterOption {
  border-bottom-left-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="filter"] .PinturaRadioGroupOptions .PinturaRadioGroupOption:last-of-type .PinturaFilterPreview {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="filter"] .PinturaRadioGroupOptions .PinturaRadioGroupOption:last-of-type .PinturaFilterOption {
  border-bottom-right-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="filter"] .PinturaRadioGroupOptionGroup {
  flex-direction: column-reverse;
  display: flex;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterOption {
  cursor: var(--button-cursor);
  margin-bottom: 1em;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterOption span {
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 1px;
  padding: 0 .3125em;
  font-size: .625em;
  font-weight: 400;
  line-height: 1.75;
  display: block;
  overflow: hidden;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterPreview:before {
  transition: background-color var(--transition-duration-10), color var(--transition-duration-10);
}

.PinturaUtilPanel[data-util="filter"] [data-selected="true"] .PinturaFilterOption .PinturaFilterPreview {
  z-index: 1;
  position: relative;
}

.PinturaUtilPanel[data-util="filter"] [data-selected="true"] .PinturaFilterOption .PinturaFilterPreview:before {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .35;
  border-radius: inherit;
  background-color: var(--color-primary);
  position: absolute;
}

.PinturaUtilPanel[data-util="filter"] [data-focus-visible] + label > .PinturaFilterOption span {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  border-radius: .25em;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterOption, .PinturaUtilPanel[data-util="filter"] .PinturaFilterPreview {
  width: 3.75em;
  height: 4.5em;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="filter"] .PinturaUtilFooter > div {
  padding: 0;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="filter"] .PinturaFilterOption, .PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="filter"] .PinturaFilterPreview {
  width: 2.875em;
  height: 3.5em;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="filter"] .PinturaFilterOption span {
  font-size: .5em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaShapeStyleEditor {
  min-height: 3.375em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption {
  border: 1px dashed var(--color-foreground-15);
  color: var(--color-foreground-70);
  margin: 1em 1em 0 0;
  padding: .125em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption span {
  color: var(--color-foreground-90);
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption label {
  font-size: .75em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption img, .PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaUtilPanel[data-util="frame"] [data-selected="true"] {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.PinturaUtilPanel[data-util="frame"] [data-selected="true"] span {
  color: var(--color-foreground-90);
}

.PinturaUtilPanel[data-util="frame"] [data-layout="row"] > .PinturaRadioGroupOptions > li {
  margin: 0 .5em;
}

.PinturaUtilPanel[data-util="frame"] [data-layout="row"] > .PinturaRadioGroupOptions > li:first-child {
  margin-left: 0;
}

.PinturaUtilPanel[data-util="frame"] [data-layout="row"] > .PinturaRadioGroupOptions > li:last-child {
  margin-right: 0;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOptionGroupLabel {
  width: 1px;
  height: 1px;
  font-size: 0;
  position: absolute;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOptionGroup {
  flex-direction: column-reverse;
  display: flex;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption {
  cursor: var(--button-cursor);
  margin-bottom: 1em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption span {
  text-align: center;
  padding: 0 1.5em;
  font-size: .625em;
  font-weight: 400;
  display: block;
}

.PinturaUtilPanel[data-util="frame"] [data-focus-visible] + label > .PinturaRadioGroupOption span {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  border-radius: .25em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption {
  width: 3em;
  height: 4em;
  position: relative;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption div {
  border-color: currentColor;
  outline-color: currentColor;
  position: absolute;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption div[slot] {
  justify-content: center;
  align-items: center;
  display: flex;
  inset: -1px;
  overflow: hidden;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="frame"] .PinturaUtilFooter > div {
  padding: 0;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption {
  width: 2.25em;
  height: 3em;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption span {
  font-size: .5em;
}

.PinturaUtilPanel[data-util="resize"] form {
  justify-content: center;
  padding: 0 1em;
  display: flex;
}

.PinturaUtilPanel[data-util="resize"] .PinturaFormInner {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.PinturaUtilPanel[data-util="resize"] .PinturaFormInner > button {
  margin-left: 1em;
  position: absolute;
  left: 100%;
}

.PinturaUtilPanel[data-util="resize"] .PinturaFieldsetInner {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.PinturaUtilPanel[data-util="resize"] .PinturaResizeLabel {
  font-size: .75em;
}

.PinturaUtilPanel[data-util="resize"] .PinturaDropdownButton {
  background-color: var(--color-foreground-5);
  border-radius: var(--border-radius-round);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color var(--transition-duration-10);
  margin-left: .5em;
  margin-right: .5em;
}

.PinturaUtilPanel[data-util="resize"] .PinturaDropdownButton:hover {
  background-color: var(--color-foreground-10);
}

.PinturaUtilPanel[data-util="resize"] .PinturaDropdownButton .PinturaButtonLabel {
  font-variant-numeric: tabular-nums;
  padding: 0 1em;
  font-size: .75em;
  font-weight: 400;
  line-height: 2.65;
  display: block;
}

.PinturaUtilPanel[data-util="resize"] .PinturaDropdownButton .PinturaButtonLabel:after {
  content: "▼";
  pointer-events: none;
  margin-left: .75em;
  margin-right: -.25em;
  font-size: .6875em;
  position: relative;
  top: -.125em;
}

.PinturaUtilPanel[data-util="resize"] input[type="checkbox"] + label {
  cursor: pointer;
  outline: #0000;
  padding: 0 .125em;
  display: block;
}

.PinturaUtilPanel[data-util="resize"] input[type="checkbox"][data-focus-visible] + label {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaInputDimension {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.PinturaInputDimension label {
  text-transform: uppercase;
  pointer-events: none;
  color: var(--color-foreground-80);
  z-index: 1;
  font-size: .75em;
  position: absolute;
  right: .625em;
}

.PinturaInputDimension button, .PinturaInputDimension input, .PinturaInputDimension label {
  font-size: .75em;
  font-weight: 400;
}

.PinturaInputDimension button, .PinturaInputDimension input {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  box-sizing: border-box;
  font-variant-numeric: tabular-nums;
  width: 100%;
  max-width: 7em;
  border-radius: var(--border-radius);
  box-shadow: none;
  border: 1px solid var(--color-foreground-80);
  transition: background-color var(--transition-duration-10) ease-in-out, color var(--transition-duration-10) ease-in-out, border-color var(--transition-duration-10) ease-in-out;
  outline: #0000;
  padding: .625em 0 .625em .625em;
}

.PinturaInputDimension button::selection, .PinturaInputDimension input::selection {
  background-color: var(--color-foreground-20);
}

.PinturaInputDimension button:hover, .PinturaInputDimension input:hover {
  background-color: var(--color-foreground-10);
}

.PinturaInputDimension button:focus, .PinturaInputDimension input:focus {
  border-color: var(--color-focus-100);
}

.PinturaInputDimension input {
  background-color: var(--color-foreground-5);
}

.PinturaInputDimension input:-moz-placeholder-shown + label, .PinturaInputDimension input:-ms-input-placeholder + label, .PinturaInputDimension input:placeholder-shown + label {
  color: var(--color-foreground-20);
}

.PinturaInputDimension input::-moz-placeholder {
  color: var(--color-foreground-30);
}

.PinturaInputDimension input:-ms-input-placeholder {
  color: var(--color-foreground-30);
}

.PinturaInputDimension input::placeholder {
  color: var(--color-foreground-30);
}

.PinturaInputDimension input[data-state="invalid"] {
  border-color: var(--color-error-75);
  box-shadow: inset 0 0 .25em var(--color-error-25), 0 0 .25em var(--color-error-50);
}

.PinturaInputDimension button {
  width: 10em;
  min-height: 2.65em;
}

.PinturaInputDimension input::-webkit-inner-spin-button, .PinturaInputDimension input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.PinturaInputDimension input::-ms-clear {
  display: none;
}

.PinturaUtilPanel[data-util="crop"] .PinturaStage {
  touch-action: none;
}

.PinturaUtilPanel[data-util="crop"] .PinturaImageInfo {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.PinturaUtilPanel[data-util="crop"] .PinturaButtonCenter {
  --size: 3em;
  width: var(--size);
  height: var(--size);
  z-index: 1;
  margin-top: calc(var(--size) * (-.5) );
  margin-left: calc(var(--size) * (-.5) );
  color: #000;
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  background: #fffc;
  border-radius: 50%;
  outline: none;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.PinturaUtilPanel[data-util="crop"] .PinturaButtonCenter[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot[data-env~="short"] .PinturaUtilPanel[data-util="crop"] .PinturaUtilFooter {
  padding-bottom: .5em;
}

.PinturaModal {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --editor-modal-border-radius: 0;
  --editor-modal-overlay-opacity: .95;
  --editor-modal-shadow: 0 1em 2em -1em #00000040, 0 1.5em 1.5em -2em #00000080;
  --editor-modal-outline: inset 0 0 0 1px var(--color-foreground-3);
  width: 100%;
  height: 100vh;
  z-index: 2147483646;
  background-color: rgba(var(--color-background), var(--editor-modal-overlay-opacity, var(--editor-modal-overlay-opacity-default, .95)));
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.PinturaModal > .PinturaRoot[data-env~="is-centered"] {
  box-shadow: var(--editor-modal-shadow);
  border-radius: var(--editor-modal-border-radius);
}

.PinturaModal > .PinturaRoot[data-env~="is-centered"]:after {
  pointer-events: none;
  border-radius: inherit;
  content: "";
  box-shadow: var(--editor-modal-outline);
  z-index: 2147483646;
  position: absolute;
  inset: 0;
}

.PinturaModal[style*="--viewport-pad-footer:1"] {
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
}

.PinturaModal + .PinturaModal {
  --editor-modal-overlay-opacity-default: .75;
}

.PinturaModal + .PinturaModal .PinturaRoot:not([data-env~="is-compact"]) {
  --editor-max-width-default: calc(100vw - 8em);
  --editor-max-height-default: calc(100vh - 8em);
}

.PinturaDocumentLock, .PinturaDocumentLock body {
  height: calc(var(--pintura-document-height)  - 1px);
  box-sizing: border-box;
  overflow-y: hidden;
}

.PinturaDocumentLock body {
  position: relative;
}

.PinturaDocumentLock .PinturaModal {
  height: 100% !important;
}

.PinturaCanvas canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}

.PinturaPanel {
  --color-inset: #ffffff1a;
  --color-fill: var(--color-foreground-5);
  z-index: 2147483647;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: auto;
  box-shadow: inset 0 0 0 1px var(--color-inset), 0 .125em .25em #00000040, 0 .125em .75em #00000026, 0 .125em .125em #0000001a;
  border-radius: var(--border-radius);
  outline: #0000;
  top: 0;
  left: 0;
}

.PinturaPanel, .PinturaPanelTip {
  color: var(--color-foreground-90);
  background-color: var(--color-background-100);
  background-image: linear-gradient(var(--color-fill), var(--color-fill));
  background-blend-mode: screen;
  position: absolute;
}

.PinturaPanelTip {
  --tip-mask: transparent calc(50% - 1.5px), #000 calc(50% - 1.5px);
  width: .5em;
  height: .5em;
  z-index: -1;
  box-shadow: inset -1px -1px 0 0 var(--color-inset);
  -webkit-mask: linear-gradient(to bottom right, var(--tip-mask));
  -webkit-mask: linear-gradient(to bottom right, var(--tip-mask));
  mask: linear-gradient(to bottom right, var(--tip-mask));
  margin-top: -.25em;
  margin-left: -.25em;
}

.PinturaPanelTip[style*="top:0"] {
  box-shadow: inset 1px 1px 0 0 var(--color-inset);
  -webkit-mask: linear-gradient(to top left, var(--tip-mask));
  -webkit-mask: linear-gradient(to top left, var(--tip-mask));
  mask: linear-gradient(to top left, var(--tip-mask));
}

.PinturaTabPanels {
  flex: 1;
  grid-template-columns: 1fr;
  display: grid;
}

.PinturaTabPanel {
  grid-row-start: 1;
  grid-column-start: 1;
  position: relative;
}

.PinturaTabPanel:not([hidden]) {
  z-index: 2;
  outline: #0000;
}

.PinturaTabPanel[hidden] {
  z-index: 1;
  pointer-events: none;
  display: block !important;
}

.PinturaTabPanel[data-inert="true"] {
  visibility: hidden;
}

@supports not (aspect-ratio:1) {
  .PinturaTabPanel {
    overflow-x: hidden;
  }
}

.PinturaTabList {
  justify-content: center;
  display: flex;
}

.PinturaTabList button {
  cursor: var(--button-cursor);
  -webkit-tap-highlight-color: transparent;
  outline: #0000;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.PinturaTabList button > * {
  transition: filter var(--transition-duration-10) ease-out;
}

.PinturaTabList button[disabled] > * {
  filter: var(--filter-disabled);
}

.PinturaScrollable {
  --scrollable-feather-start-opacity: 0;
  --scrollable-feather-end-opacity: 0;
  --scrollable-feather-size: 2em;
  contain: paint;
  overflow: hidden;
}

.PinturaScrollable:focus {
  outline: #0000;
}

.PinturaScrollable > div {
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.PinturaScrollable {
  touch-action: none;
}

.PinturaScrollable[data-direction="x"] {
  max-width: 100%;
  --mask-angle: 90deg;
}

.PinturaScrollable[data-direction="y"] {
  max-height: 100%;
  --mask-angle: 180deg;
}

.PinturaScrollable[data-state~="overflows"] {
  -webkit-mask: linear-gradient(var(--mask-angle), rgba(0, 0, 0, var(--scrollable-feather-start-opacity)), #000 var(--scrollable-feather-size), #000 calc(100% - var(--scrollable-feather-size)), rgba(0, 0, 0, var(--scrollable-feather-end-opacity)) );
  -webkit-mask: linear-gradient(var(--mask-angle), rgba(0, 0, 0, var(--scrollable-feather-start-opacity)), #000 var(--scrollable-feather-size), #000 calc(100% - var(--scrollable-feather-size)), rgba(0, 0, 0, var(--scrollable-feather-end-opacity)) );
  mask: linear-gradient(var(--mask-angle), rgba(0, 0, 0, var(--scrollable-feather-start-opacity)), #000 var(--scrollable-feather-size), #000 calc(100% - var(--scrollable-feather-size)), rgba(0, 0, 0, var(--scrollable-feather-end-opacity)) );
}

.PinturaScrollable[data-state~="scrolling"] > div * {
  pointer-events: none;
}

.PinturaButton {
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  cursor: var(--button-cursor);
  flex-shrink: 0;
}

.PinturaButton > span {
  vertical-align: top;
  min-height: 1em;
  align-items: center;
  display: inline-flex;
}

.PinturaButton svg + .PinturaButtonLabel {
  margin-left: .5em;
}

.PinturaButton[disabled] {
  cursor: default;
}

.PinturaButton:not(.PinturaButtonIconOnly) svg:first-of-type {
  margin-left: -.25em;
}

.PinturaProgressIndicator {
  width: 1.25em;
  height: 1.25em;
  display: block;
  position: relative;
}

.PinturaProgressIndicator .PinturaProgressIndicatorFill {
  stroke-dashoffset: 0;
}

.PinturaProgressIndicator[data-status="busy"] svg {
  animation: 1s linear infinite image_editor_spin;
}

.PinturaProgressIndicator[data-status="busy"] .PinturaProgressIndicatorFill {
  stroke-dashoffset: -26.5px;
  stroke-dasharray: 26.5 53;
  transition: stroke-dashoffset .5s linear, stroke-dasharray .5s linear;
}

@keyframes image_editor_spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .PinturaRoot[data-env~="is-ios"] .PinturaProgressIndicator svg {
    transform-origin: 49% 49%;
  }
}

.PinturaUtilPanel {
  z-index: 1;
  height: 100%;
  contain: layout size;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.PinturaUtilPanel, .PinturaUtilPanel > * {
  padding-top: .5em;
  padding-bottom: .5em;
}

.PinturaSlider {
  --track-offset-x: .5em;
  --track-offset-y: .5em;
  --track-size: 1.5em;
  --knob-size: 1em;
  --knob-margin-top: 0em;
  --knob-margin-right: 0em;
  --knob-margin-bottom: 0em;
  --knob-margin-left: 0em;
  touch-action: none;
  align-items: center;
  display: flex;
  position: relative;
}

.PinturaSlider[data-direction="y"] {
  flex-direction: column;
}

.PinturaSlider .PinturaSliderControl {
  flex: 1;
  position: relative;
}

.PinturaSlider > button {
  z-index: 1;
  width: var(--track-size);
  height: var(--track-size);
  cursor: pointer;
  flex-shrink: none;
  position: relative;
  overflow: hidden;
}

.PinturaSlider > button svg {
  width: 1.25em;
  height: 1.25em;
  stroke-width: .125em;
  stroke: var(--color-foreground-50);
  margin: .125em;
}

.PinturaSlider > button:first-of-type {
  order: 2;
  margin-left: -.325em;
  padding-right: .125em;
}

.PinturaSlider > button:last-of-type {
  order: 1;
  margin-right: -.325em;
  padding-left: .125em;
}

.PinturaSlider > .PinturaSliderControl {
  order: 2;
}

.PinturaSlider .PinturaSliderKnob {
  visibility: visible;
  width: var(--knob-size);
  height: var(--knob-size);
  margin-left: calc(var(--knob-size) * -.5);
  margin-top: calc(var(--knob-size) * -.5);
  border-radius: 9999em;
  top: 50%;
  left: 0;
}

.PinturaSlider .PinturaSliderKnob, .PinturaSlider .PinturaSliderKnobController, .PinturaSlider .PinturaSliderTrack {
  pointer-events: none;
  position: absolute;
}

.PinturaSlider .PinturaSliderTrack {
  top: var(--track-offset-y);
  bottom: var(--track-offset-y);
  left: var(--track-offset-x);
  right: var(--track-offset-x);
}

.PinturaSlider .PinturaSliderKnobController {
  visibility: hidden;
  top: calc(var(--track-offset-y)  + var(--knob-margin-top));
  bottom: calc(var(--track-offset-y)  + var(--knob-margin-bottom));
  left: calc(var(--track-offset-x)  + var(--knob-margin-left));
  right: calc(var(--track-offset-x)  + var(--knob-margin-right));
}

.PinturaSlider .PinturaSliderTrack {
  border-radius: 9999em;
}

.PinturaSlider[data-direction="x"] input[type="range"] {
  height: var(--track-size);
  width: calc(100% - 1em);
  margin-left: .5em;
}

.PinturaSlider[data-direction="y"] input[type="range"] {
  width: var(--track-size);
  height: calc(100% - 1em);
  margin-top: .5em;
}

.PinturaSlider input[type="range"] {
  -webkit-appearance: none;
  display: block;
}

.PinturaSlider input[type="range"]::-webkit-slider-runnable-track {
  background: none;
}

.PinturaSlider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 0;
}

.PinturaSlider input[type="range"]::-moz-range-thumb {
  border: none;
  margin: 0;
  padding: 0;
  line-height: 0;
}

.PinturaSlider input[type="range"]::-ms-thumb {
  border: none;
  margin: 0;
  padding: 0;
  line-height: 0;
}

.PinturaSlider input[type="range"]::-ms-ticks-before, .PinturaSlider input[type="range"]::-ms-tooltip {
  display: none;
}

.PinturaSlider input[type="range"]::-ms-ticks-after {
  display: none;
}

.PinturaSlider input[type="range"]::-ms-track {
  color: #0000;
  background: none;
  border: none;
}

.PinturaSlider input[type="range"]::-ms-fill-lower, .PinturaSlider input[type="range"]::-ms-fill-upper {
  background: none;
}

.PinturaSlider input[type="range"]:focus {
  outline: #0000;
}

.PinturaSlider input[type="range"][data-focus-visible] ~ .PinturaSliderKnobController .PinturaSliderKnob:after {
  border-radius: inherit;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  pointer-events: none;
  position: absolute;
  inset: -.3125em;
}

.PinturaColorPreview {
  --color: transparent;
  color: var(--color);
  pointer-events: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h4v4H0zm4 4h4v4H4z' fill='%23E5E5E5'/%3E%3C/svg%3E");
  position: relative;
}

.PinturaColorPreview span {
  background-color: var(--color);
  border-radius: inherit;
  position: absolute;
  inset: 0;
}

.PinturaRangeInput {
  height: 3.5em;
  max-width: 24em;
  width: calc(100% - 2em);
  touch-action: none;
  --range-input-feather-left: 2em;
  --range-input-feather-right: 2em;
  --range-input-mask-from: 0%;
  --range-input-mask-to: 100%;
  --range-input-mask-color: #00000040;
  --range-input-line-color: var(--color-foreground-15);
  outline: #0000;
  flex-direction: column;
  align-items: center;
  margin: auto;
  display: flex;
  position: relative;
}

.PinturaRangeInput[data-focus-visible]:after {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  content: "";
  height: 2em;
  border-radius: .25em;
  position: absolute;
  top: calc(50% - 1em);
  left: 0;
  right: 0;
}

.PinturaRangeInputInner {
  height: 100%;
  width: 100%;
  contain: strict;
  -webkit-mask: linear-gradient(90deg, transparent 0, #000 var(--range-input-feather-left), #000 calc(50% - 3em), transparent 50%, #000 calc(50% + 3em), #000 calc(100% - var(--range-input-feather-right)), transparent);
  -webkit-mask: linear-gradient(90deg, transparent 0, #000 var(--range-input-feather-left), #000 calc(50% - 3em), transparent 50%, #000 calc(50% + 3em), #000 calc(100% - var(--range-input-feather-right)), transparent);
  mask: linear-gradient(90deg, transparent 0, #000 var(--range-input-feather-left), #000 calc(50% - 3em), transparent 50%, #000 calc(50% + 3em), #000 calc(100% - var(--range-input-feather-right)), transparent);
  position: relative;
  overflow: hidden;
}

.PinturaRangeInputMeter {
  height: 100%;
}

.PinturaRangeInput .PinturaRangeInputMeter svg {
  pointer-events: none;
  fill: currentColor;
  display: block;
}

.PinturaRangeInput > .PinturaRangeInputValue {
  z-index: 1;
  pointer-events: none;
  width: 3.5em;
  height: 1.5em;
  contain: strict;
  text-align: center;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-size: .75em;
  position: absolute;
  top: calc(50% - .6875em);
  left: calc(50% - 1.75em);
  overflow: hidden;
}

.PinturaRangeInput > .PinturaRangeInputReset {
  z-index: 1;
  width: 2em;
  transition: opacity var(--transition-duration-25);
  opacity: .35;
  cursor: var(--button-cursor);
  outline: #0000;
  margin-top: .125em;
  padding: 0;
  position: absolute;
  top: 0;
  left: calc(50% - 1.0625em);
  overflow: hidden;
  color: #0000 !important;
}

.PinturaRangeInput > .PinturaRangeInputReset:hover {
  opacity: .5;
}

.PinturaRangeInput > .PinturaRangeInputReset[data-focus-visible] {
  opacity: 1;
}

.PinturaRangeInput > .PinturaRangeInputReset[data-focus-visible]:before {
  content: "";
  pointer-events: none;
  height: 1em;
  width: 1em;
  z-index: 2;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  border-radius: .25em;
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
}

.PinturaRangeInput > .PinturaRangeInputReset[disabled] {
  opacity: .15;
}

.PinturaRangeInput > .PinturaRangeInputReset:after {
  content: "";
  width: 1px;
  height: 60%;
  background: var(--color-foreground-100);
  position: absolute;
  top: 20%;
  left: 50%;
}

.PinturaRangeInputInner[data-value-limited] svg {
  -webkit-mask: linear-gradient(90deg, var(--range-input-mask-color) var(--range-mask-from), #000 var(--range-mask-from), #000 var(--range-mask-to), var(--range-input-mask-color) var(--range-mask-to));
  -webkit-mask: linear-gradient(90deg, var(--range-input-mask-color) var(--range-mask-from), #000 var(--range-mask-from), #000 var(--range-mask-to), var(--range-input-mask-color) var(--range-mask-to));
  mask: linear-gradient(90deg, var(--range-input-mask-color) var(--range-mask-from), #000 var(--range-mask-from), #000 var(--range-mask-to), var(--range-input-mask-color) var(--range-mask-to));
}

.PinturaRangeInputInner[data-value-limited] svg rect {
  x: var(--range-mask-from);
  width: calc(var(--range-mask-to)  - var(--range-mask-from));
  fill: var(--range-input-line-color);
}

.PinturaUtilMain {
  min-height: 1px;
  padding-left: 1em;
  padding-right: 1em;
}

.PinturaUtilMain, .PinturaUtilMain > [slot] {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.PinturaUtilMain > [slot] {
  align-items: center;
}

.PinturaUtilFooter > [slot]:empty {
  min-height: 1em;
}

.PinturaUtilFooter > [slot] {
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  display: flex;
}

.PinturaUtilFooter .PinturaControlPanel .PinturaShapePresetsList, .PinturaUtilFooter .PinturaShapeStyleList {
  min-height: 3.5em;
  align-items: flex-start;
}

.PinturaUtilFooter .PinturaControlList [role="tab"][aria-selected="true"] button {
  background-color: var(--color-foreground-10);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label, .PinturaUtilFooter .PinturaControlList [role="tab"] button {
  min-height: 1.75em;
  border-radius: var(--border-radius-round);
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out, filter var(--transition-duration-25) ease-out;
  box-shadow: inset 0 0 0 1px var(--color-foreground-0);
  cursor: var(--button-cursor);
  flex-direction: row;
  padding: 0 .75em;
  line-height: 1;
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label span, .PinturaUtilFooter .PinturaControlList [role="tab"] button span {
  font-size: .75em;
  font-weight: 400;
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label svg, .PinturaUtilFooter .PinturaControlList [role="tab"] button svg {
  width: .875em;
  font-size: .875em;
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label svg:not(:only-child), .PinturaUtilFooter .PinturaControlList [role="tab"] button svg:not(:only-child) {
  margin-left: -.25em;
  margin-right: .375em;
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label:hover, .PinturaUtilFooter .PinturaControlList [role="tab"] button:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label[data-focus-visible], .PinturaUtilFooter .PinturaControlList [role="tab"] button[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaUtilFooter .PinturaControlListOption [slot], .PinturaUtilFooter .PinturaControlListOption label {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.PinturaUtilFooter .PinturaControlListOption[data-selected="true"] label {
  background-color: var(--color-foreground-10);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaUtilFooter .PinturaControlListOption input[data-focus-visible] + label {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaUtilFooter .PinturaControlListOption input[disabled] + label {
  filter: var(--filter-disabled);
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption + .PinturaControlListOption, .PinturaUtilFooter .PinturaControlList > li + li {
  margin-left: .5em;
}

.PinturaUtilFooter .PinturaControlPanels {
  width: 40em;
  max-width: 100%;
  flex: auto;
  grid-template-columns: 1fr;
  margin: 0 auto;
  display: grid;
}

.PinturaUtilFooter .PinturaControlPanel {
  max-width: 100%;
  visibility: visible;
  opacity: 1;
  grid-row-start: 1;
  grid-column-start: 1;
  overflow: hidden;
}

.PinturaUtilFooter .PinturaControlPanel[hidden] {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition-delay: 0s;
  display: block !important;
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > * + * {
  margin-left: -1px;
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > [role="tab"] button {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  border-radius: 0;
  padding-left: .625em;
  padding-right: .625em;
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > [role="tab"]:only-child button {
  border-radius: var(--border-radius-round);
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > [role="tab"]:first-child:not(:only-child) button {
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
  padding-left: .75em;
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > [role="tab"]:last-child:not(:only-child) button {
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
  padding-right: .75em;
}

@supports not (aspect-ratio:1) {
  .PinturaControlPanel {
    overflow-x: hidden;
  }
}

.PinturaRoot[data-env~="is-animated"] .PinturaUtilFooter .PinturaControlPanel {
  transition: opacity var(--transition-duration-25) ease-in-out, visibility var(--transition-duration-25) linear;
  transition-delay: .1s;
}

.PinturaRoot[data-env~="has-controlgroups-preference-top"] .PinturaUtilHeader {
  order: 1;
}

.PinturaRoot[data-env~="has-controlgroups-preference-top"] .PinturaUtilFooter {
  order: 2;
}

.PinturaRoot[data-env~="has-controlgroups-preference-top"] .PinturaUtilMain {
  order: 3;
}

.PinturaRoot[data-env~="has-controltabs-preference-bottom"] .PinturaUtilFooter .PinturaControlPanels {
  order: -1;
}

.PinturaRoot[data-env~="has-controltabs-preference-bottom"] .PinturaUtilFooter .PinturaRangeInput .PinturaRangeInputReset {
  top: 0;
  bottom: auto;
}

.PinturaRoot[data-env~="has-controltabs-preference-bottom"] .PinturaUtilFooter .PinturaControlPanel .PinturaShapePresetsGrouped {
  padding-bottom: .75em;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaControlPanels {
  order: 1;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaRangeInput .PinturaRangeInputReset {
  top: auto;
  bottom: 0;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaControlPanel .PinturaShapePresetsGrouped {
  padding-top: .75em;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaControlPanel .PinturaShapePresetsList, .PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaShapeStyleList {
  align-items: flex-end;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaShapeStyleLabel {
  order: 1;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter {
  margin: .75em 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlPanels {
  max-width: 24em;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList {
  margin-top: 1em;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList > li + li {
  margin-left: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList li[role="tab"] span {
  font-size: .75em;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList li[role="tab"] button {
  background-color: var(--color-background-50);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  box-shadow: inset 0 0 0 1px var(--color-background-15), 0 .0625em .125em #00000040, 0 .125em .35em #00000059;
  border-radius: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList li[role="tab"]:first-child button {
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
  padding-left: .875em;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList li[role="tab"]:last-child button {
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
  padding-right: .875em;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList li:not([aria-selected="true"]) span {
  color: var(--color-foreground-40);
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlPanels {
  background-color: var(--color-background-50);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  border-radius: var(--border-radius-round);
  box-shadow: inset 0 0 0 1px var(--color-background-15), 0 .0625em .125em #00000040, 0 .125em .35em #00000059;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaRangeInput {
  height: 1.5em;
  margin-top: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaRangeInputReset {
  opacity: 0;
}

.PinturaPresetListFilter .PinturaRadioGroupOptions {
  justify-content: space-evenly;
  padding: .625em 0 .3125em;
}

.PinturaPresetListFilter .PinturaRadioGroupOptions label {
  cursor: pointer;
  display: block;
}

.PinturaPresetListFilter + .PinturaOptionsList {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.PinturaImageInfo {
  pointer-events: none;
}

.PinturaImageInfo p {
  width: 8em;
  height: 1.25em;
  contain: strict;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  text-align: right;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-size: .75em;
  line-height: 1;
  overflow: hidden;
}

.PinturaShapeStyleEditor {
  grid-template-columns: 1fr;
  display: grid;
  position: relative;
}

.PinturaShapeStyleEditor > div {
  max-width: 100%;
  grid-row-start: 1;
  grid-column-start: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.PinturaShapeStyles {
  position: relative;
}

.PinturaShapeStyles:not([data-state~="overflows"]) {
  justify-content: center;
  display: flex;
}

.PinturaShapeStyleList {
  display: flex;
}

.PinturaShapeStyleList .PinturaButton, .PinturaShapeStyleList .PinturaInput {
  outline: #0000;
}

.PinturaShapeStyleList .PinturaInput {
  padding: 0 0 0 .625em;
  font-size: .75em;
  line-height: 2.25;
}

.PinturaShapeStyleList .PinturaShapeStyleLabel {
  text-transform: lowercase;
  pointer-events: none;
  text-align: center;
  color: var(--color-foreground-50);
  white-space: nowrap;
  padding: 0 .75em;
  font-size: .625em;
  font-weight: 300;
  line-height: 1.75;
}

.PinturaShapeStyleList > .PinturaShapeStyle {
  border-radius: var(--border-radius-round);
}

.PinturaShapeStyleList > .PinturaShapeStyle + .PinturaShapeStyle {
  margin-left: 1em;
}

.PinturaShapeStyle {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.PinturaShapeStyle .PinturaPanelButton {
  border-radius: inherit;
}

.PinturaShapeStyle > .PinturaButton:hover, .PinturaShapeStyle > .PinturaButton[data-focus-visible] {
  z-index: 1;
  position: relative;
}

.PinturaShapeStyle > .PinturaColorPickerButton .PinturaButtonLabel {
  border-radius: inherit;
  display: block;
}

.PinturaShapeStyle > .PinturaColorPickerButton {
  justify-content: center;
  display: flex;
}

.PinturaShapeStyle .PinturaColorPreview {
  border-radius: inherit;
  width: 1.5em;
  height: 1.5em;
  background-color: #fff;
  background-size: 4px;
}

.PinturaShapeStyle .PinturaColorPreview span {
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaShapeStyle [data-focus-visible] .PinturaColorPreview span:before {
  border-radius: inherit;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

.PinturaShapeStyle > .PinturaColorPickerButtonStroke .PinturaColorPreview {
  -webkit-mask: radial-gradient(circle, #0000 5.5px, #000 6px) 0 0 / cover;
  mask: radial-gradient(circle, #0000 5.5px, #000 6px) 0 0 / cover;
}

.PinturaShapeStyle > .PinturaColorPickerButtonStroke .PinturaColorPreview span:after {
  border-radius: inherit;
  box-shadow: 0 0 0 1px var(--color-foreground-5);
  content: "";
  position: absolute;
  inset: 6px;
}

.PinturaShapeStyle > .PinturaDropdownButton .PinturaButtonLabel, .PinturaShapeStyle > .PinturaRadioGroup .PinturaButtonLabel, .PinturaShapeStyle > .PinturaRadioGroupOption label, .PinturaShapeStyle > .PinturaSliderButton .PinturaButtonLabel {
  padding: 0 1em;
  font-size: .6875em;
  line-height: 2.25;
  display: block;
}

.PinturaShapeStyle > .PinturaButton:not(.PinturaColorPickerButton)[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapeStyle .PinturaInputField {
  text-align: right;
  width: 3em;
  height: 2em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  border-radius: var(--border-radius);
  padding: 0 .75em;
}

.PinturaShapeStyle > .PinturaDropdownButton {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color var(--transition-duration-10);
}

.PinturaShapeStyle > .PinturaDropdownButton:hover {
  background-color: var(--color-foreground-10);
}

.PinturaShapeStyle > .PinturaDropdownButton .PinturaButtonLabel:after {
  content: "▼";
  pointer-events: none;
  margin-left: .75em;
  margin-right: -.25em;
  font-size: .6875em;
  position: relative;
  top: -.125em;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions {
  flex-direction: row;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions label {
  cursor: var(--button-cursor);
  height: 1.5em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color var(--transition-duration-10);
  align-items: center;
  padding: 0 .25em;
  display: flex;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions label:hover {
  background-color: var(--color-foreground-20);
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions svg {
  width: 1.125em;
  height: 1.125em;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions .PinturaRadioGroupOption:first-of-type label {
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
  padding-left: .5em;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions .PinturaRadioGroupOption:last-of-type label {
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
  padding-right: .5em;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions [data-selected="true"] label {
  background-color: var(--color-foreground-10);
}

.PinturaToolbar {
  justify-content: center;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
}

.PinturaToolbar .PinturaToolbarInner {
  display: inline-flex;
}

.PinturaToolbar[data-layout="compact"] .PinturaToolbarContentWide, .PinturaToolbar[data-overflow="overflow"] .PinturaToolbarContentOptional {
  display: none;
}

.PinturaToolbar .PinturaButton {
  border-radius: var(--border-radius-round);
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out, box-shadow var(--transition-duration-10) ease-out;
  outline: #0000;
  padding: .3125em .75em;
  line-height: 1.1;
}

.PinturaToolbar .PinturaButton:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaToolbar .PinturaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaToolbar .PinturaButton .PinturaButtonIcon {
  width: 1em;
}

.PinturaToolbar .PinturaButtonLabel {
  font-size: .75em;
}

.PinturaToolbar .PinturaPanel {
  z-index: 3;
}

.PinturaToolbar .PinturaToolbarInner > * {
  margin: 0 .5em;
}

.PinturaRectManipulator {
  --size: 1.25em;
  touch-action: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaRectManipulator:not([data-shape="edge"]) {
  width: var(--size);
  height: var(--size);
  z-index: 2;
}

.PinturaRectManipulator:not([data-shape="edge"]):after {
  border-radius: inherit;
  content: "";
  position: absolute;
  inset: -.5em;
}

.PinturaRectManipulator:not([data-shape="edge"])[data-focus-visible]:after {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRectManipulator[data-shape~="circle"] {
  margin-left: calc(var(--size) * (-.5) );
  margin-top: calc(var(--size) * (-.5) );
  line-height: var(--size);
  background-color: var(--color-foreground-100);
  box-shadow: 0 0 2px var(--color-background-50);
  border-radius: 50%;
}

.PinturaRectManipulator[data-shape="hook"] {
  border: 3px solid var(--color-foreground-100);
}

.PinturaRectManipulator[data-shape="hook"][data-direction*="t"] {
  border-bottom: 0;
  margin-top: -3px;
}

.PinturaRectManipulator[data-shape="hook"][data-direction*="r"] {
  margin-left: calc(var(--size) * (-1) );
  border-left: 0;
}

.PinturaRectManipulator[data-shape="hook"][data-direction*="b"] {
  margin-top: calc(var(--size) * (-1) );
  border-top: 0;
}

.PinturaRectManipulator[data-shape="hook"][data-direction*="l"] {
  border-right: 0;
  margin-left: -3px;
}

.PinturaRectManipulator[data-shape~="edge"] {
  height: 1px;
  width: 1px;
  z-index: 1;
}

.PinturaRectManipulator[data-shape~="edge"][data-focus-visible] {
  background-color: var(--color-focus-50);
}

.PinturaRectManipulator[data-direction="b"], .PinturaRectManipulator[data-direction="t"] {
  cursor: ns-resize;
  height: var(--size);
  margin-top: calc(var(--size) * (-.5) );
  transform-origin: 0;
}

.PinturaRectManipulator[data-direction="l"], .PinturaRectManipulator[data-direction="r"] {
  cursor: ew-resize;
  width: var(--size);
  margin-left: calc(var(--size) * (-.5) );
  transform-origin: 50% 0;
}

.PinturaRectManipulator[data-direction="bl"], .PinturaRectManipulator[data-direction="tr"] {
  cursor: nesw-resize;
}

.PinturaRectManipulator[data-direction="br"], .PinturaRectManipulator[data-direction="tl"] {
  cursor: nwse-resize;
}

.PinturaRoot[data-env~="pointer-coarse"] .PinturaRectManipulator[data-shape="edge"]:active {
  background-color: var(--color-foreground-5);
}

.PinturaRoot[data-env~="pointer-coarse"] .PinturaRectManipulator:not([data-shape="edge"]):after {
  inset: -1em;
}

.PinturaRoot[data-env~="pointer-coarse"] .PinturaRectManipulator:not([data-shape="edge"]):active:after {
  background-color: var(--color-foreground-5);
}

.PinturaShapePresetsPalette {
  text-align: center;
  width: 40em;
  max-width: 100%;
  overflow: hidden;
}

.PinturaShapePresetsPalette .PinturaShapePresetSelect {
  vertical-align: top;
  display: inline-block;
}

.PinturaShapePresetsPalette .PinturaScrollable:not([data-state~="overflows"]) {
  justify-content: center;
  display: flex;
}

.PinturaShapePresetsPalette .PinturaButton + .PinturaButton {
  margin-left: .5em;
}

.PinturaShapePresetsPalette .PinturaButton {
  border-radius: var(--border-radius-round);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  cursor: var(--button-cursor);
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out, box-shadow var(--transition-duration-10) ease-out;
  outline: none;
  flex-direction: row;
  padding: .3125em .75em;
  line-height: 1.1;
}

.PinturaShapePresetsPalette .PinturaButton .PinturaButtonIcon {
  width: 1em;
}

.PinturaShapePresetsPalette .PinturaButton .PinturaButtonLabel {
  font-size: .75em;
}

.PinturaShapePresetsPalette .PinturaButton:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}

.PinturaShapePresetsPalette .PinturaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapePresetsPalette .PinturaShapePresetsFlat > * + *, .PinturaShapePresetsPalette .PinturaShapePresetsGroups > * + *, .PinturaShapePresetsPalette .PinturaShapePresetsGroups > div > * + * {
  margin-left: .5em;
}

.PinturaShapePresetsGroups {
  display: flex;
}

.PinturaShapePresetsGrouped {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.PinturaShapePresetsGrouped [role="tab"] {
  white-space: nowrap;
}

.PinturaShapePresetsFlat {
  justify-content: center;
  align-items: center;
  display: flex;
}

.PinturaShapePresetsFlat > .PinturaButton {
  margin-left: 1em;
}

.PinturaShapeList {
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--transition-duration-10) ease-in-out;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1;
  border-radius: var(--border-radius);
  color: var(--color-foreground-100);
  background: var(--color-background-70);
  box-shadow: 0 0 0 1px var(--color-foreground-5);
  position: absolute;
  top: 1em;
  left: 1em;
}

.PinturaShapeList li + li {
  margin-top: -.3125em;
}

.PinturaShapeList[data-visible="true"] {
  opacity: 1;
}

.PinturaShapeList .PinturaColorPreview {
  width: .75em;
  height: .75em;
  border-radius: .25em;
  margin-right: .25em;
}

.PinturaShapeList .PinturaShapeListItem {
  outline: none;
  align-items: center;
  padding: .25em .5em;
  display: flex;
}

.PinturaShapeList .PinturaShapeListItem > span {
  margin-left: -.325em;
  padding: 0 .325em;
  font-size: .75em;
  line-height: 1.75;
}

.PinturaShapeList .PinturaShapeListItem[data-focus-visible] > span {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapeList[data-visible="true"] .PinturaShapeListItem {
  pointer-events: all;
}

.PinturaShapeEditor {
  height: 100%;
  width: 100%;
  touch-action: none;
  outline: none;
}

.PinturaShapeControls {
  z-index: 3;
  pointer-events: none;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
  position: absolute !important;
}

.PinturaShapeControlsGroup {
  pointer-events: all;
  color: #eee;
  background: #222;
  border-radius: .25em;
  box-shadow: 0 .125em .25em #00000040;
}

.PinturaShapeControlsGroup, .PinturaShapeControlsGroup .PinturaShapeControlsGroupToggle {
  min-height: 1.75em;
  display: inline-flex;
}

.PinturaShapeControlsGroup + .PinturaShapeControlsGroup {
  margin-left: .5em;
}

.PinturaShapeControlsGroup .PinturaShapeControlsGroupToggle {
  position: relative;
}

.PinturaShapeControlsGroup .PinturaButton {
  outline: #0000;
}

.PinturaShapeControlsGroup .PinturaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapeControlsGroup .PinturaButton[disabled] > span {
  opacity: .5;
  pointer-events: all;
  cursor: not-allowed;
}

.PinturaShapeControlsGroup > .PinturaButton {
  transition: background-color var(--transition-duration-10);
  outline: #0000;
  font-weight: 700;
  display: block;
}

.PinturaShapeControlsGroup > .PinturaButton:hover {
  background-color: #ffffff26;
}

.PinturaShapeControlsGroup > .PinturaButton .PinturaButtonLabel {
  padding: 0 .75em;
  font-size: .6875em;
  line-height: 1.7em;
  display: block;
}

.PinturaShapeControlsGroup .PinturaButtonIconOnly {
  width: 1.75em;
  transition: background-color var(--transition-duration-10);
}

.PinturaShapeControlsGroup .PinturaButtonIconOnly:hover {
  background-color: #ffffff26;
}

.PinturaShapeControlsGroup > button:first-of-type {
  border-radius: .25em 0 0 .25em;
}

.PinturaShapeControlsGroup > button:last-of-type {
  border-radius: 0 .25em .25em 0;
}

.PinturaShapeControlsGroup > button:only-of-type {
  border-radius: .25em;
}

.PinturaShapeControlsGroup > * + * {
  border-left: 1px solid #00000080;
}

.PinturaShapeControlsGroup svg {
  pointer-events: none;
}

.PinturaInlineInput {
  transform-origin: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaInlineInput:before {
  content: "";
  width: 0;
  height: var(--line-height);
}

.PinturaContentEditable {
  width: 100%;
  height: calc(100% - var(--bottom-inset, 0));
  vertical-align: top;
  outline: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.PinturaShapePreset, .PinturaShapePresetsList {
  align-items: center;
  display: flex;
}

.PinturaShapePreset {
  --thumb-size: 1.75em;
  --thumb-radius: .3125em;
  --thumb-margin: .3125em;
  justify-content: center;
  position: relative;
}

.PinturaShapePreset text {
  font-family: monospace;
}

.PinturaShapePreset img, .PinturaShapePreset svg {
  width: var(--thumb-size);
  height: var(--thumb-size);
  min-width: auto;
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: var(--thumb-radius);
  display: block;
}

.PinturaShapePreset .PinturaDragButton {
  padding: var(--thumb-margin);
  border-radius: var(--border-radius);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaShapePreset .PinturaDragButton:hover {
  background-color: var(--color-foreground-10);
}

.PinturaShapePreset .PinturaDragButton[data-loader]:after, .PinturaShapePreset .PinturaDragButton[data-loader]:before {
  width: var(--thumb-size);
  height: var(--thumb-size);
  line-height: var(--thumb-size);
  border-radius: var(--thumb-radius);
  display: block;
}

.PinturaShapePreset .PinturaDragButton[data-error] img {
  display: none;
}

.PinturaShapePreset .PinturaDragButton[data-error]:after {
  content: "⚠";
  box-shadow: inset 0 0 0 1px var(--color-error-75);
  background-color: var(--color-error-10);
  color: var(--color-error-75);
}

.PinturaShapePreset .PinturaDragButton[data-loader] {
  position: relative;
}

.PinturaShapePreset .PinturaDragButton[data-loader]:before {
  content: "";
  left: var(--thumb-margin);
  top: var(--thumb-margin);
  -webkit-animation: pintura-img-loading calc(var(--transition-duration-multiplier, 0) * 1s) infinite alternate ease-in-out;
  animation: pintura-img-loading calc(var(--transition-duration-multiplier, 0) * 1s) infinite alternate ease-in-out;
  background-color: var(--color-foreground-10);
  transition: transform calc(var(--transition-duration-multiplier, 0) * .5s) ease-in;
  z-index: -1;
  position: absolute;
}

.PinturaShapePreset .PinturaDragButton[data-loader] img {
  opacity: 0;
  transition: opacity calc(var(--transition-duration-multiplier, 0) * .35s) ease-out;
}

.PinturaShapePreset .PinturaDragButton[data-loader][data-load] img {
  opacity: 1;
}

.PinturaShapePreset .PinturaDragButton[data-loader][data-error]:before, .PinturaShapePreset .PinturaDragButton[data-loader][data-load]:before {
  animation-play-state: paused;
  transform: scale(.5);
}

.PinturaShapePreset + .PinturaShapePreset {
  margin-left: .5em;
}

@keyframes pintura-img-loading {
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: .5;
  }
}

.PinturaInputForm {
  opacity: 0;
  cursor: auto;
  z-index: 2147483647;
}

.PinturaInputForm:not([data-layout="stick"]) {
  width: 30em;
  box-shadow: 0 .25em .5em -.5em #000000bf, inset 0 0 0 1px var(--color-foreground-10);
  border-radius: var(--border-radius);
  background-color: var(--color-background-95);
  position: absolute;
  bottom: 6.5em;
  left: calc(50% - 15em);
}

@media (max-width: 34em) {
  .PinturaInputForm:not([data-layout="stick"]) {
    width: auto;
    left: 2em;
    right: 2em;
  }
}

.PinturaInputForm[data-layout="stick"] {
  outline: 1px solid var(--color-foreground-15);
  background-color: var(--color-background-100);
  transition: opacity .15s .25s;
  position: absolute;
  left: 0;
  right: 0;
}

.PinturaInputForm[data-layout="stick"] > .PinturaInputFormInner {
  background-color: var(--color-foreground-3);
}

.PinturaInputFormInner > .PinturaButton {
  color: var(--color-foreground-100);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  border-radius: 9999em;
}

.PinturaInputFormInner > .PinturaButton:first-of-type {
  left: 1em;
}

.PinturaInputFormInner > .PinturaButton:last-of-type {
  color: var(--color-primary-text);
  background: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
  right: 1rem;
}

.PinturaInputFormInner > .PinturaButton:not(.PinturaButtonIconOnly) .PinturaButtonInner {
  padding: 0 .875em 0 .625em;
}

.PinturaInputFormInner > .PinturaButton .PinturaButtonIcon {
  width: .875em;
  height: .875em;
}

.PinturaInputFormInner > .PinturaButton.PinturaButtonIconOnly {
  width: 1.875em;
  height: 1.875em;
}

.PinturaInputFormInner > .PinturaButton .PinturaButtonLabel {
  font-size: .875em;
}

.PinturaInputFormInner > .PinturaButton .PinturaButtonInner {
  justify-content: center;
  line-height: 2;
  display: flex;
}

.PinturaInputFormInner {
  padding-top: 1em;
  padding-bottom: 3.5em;
}

.PinturaInputFormInner > .PinturaButton {
  position: absolute;
  bottom: 1em;
}

.PinturaInputFormFields textarea:only-child {
  width: 100%;
  height: 5em;
  background: none;
  padding: 0 1em .5em;
  display: block;
  -webkit-mask: linear-gradient(0deg, #0000, #000 1em);
  mask: linear-gradient(0deg, #0000, #000 1em);
}

.PinturaInputFormFields textarea:only-child::-webkit-scrollbar {
  width: 1em;
  cursor: pointer;
}

.PinturaInputFormFields textarea:only-child::-webkit-scrollbar-track {
  background: none;
}

.PinturaInputFormFields textarea:only-child::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-clip: padding-box;
  background-color: var(--color-foreground-70);
  border: .25em solid #0000;
  border-radius: 9999em;
}

.PinturaInputForm input, .PinturaInputForm textarea {
  box-sizing: border-box;
  resize: none;
  color: var(--color-foreground-100);
  border: none;
  outline: #0000;
  font-size: 1rem;
}

.PinturaInputForm input {
  background-color: var(--color-background-100);
}

.PinturaModalBodyLock .PinturaInputForm {
  margin-top: -50px;
}

.PinturaContentEditable {
  white-space: pre-line !important;
  word-break: break-word !important;
  -webkit-user-select: text !important;
  user-select: text !important;
  display: inline-block !important;
}

.PinturaContentEditable[data-wrap-content="nowrap"] {
  white-space: nowrap !important;
  word-break: normal !important;
}

.PinturaContentEditable {
  max-width: none !important;
  min-width: auto !important;
  box-shadow: none !important;
  background: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.PinturaShapeManipulator {
  touch-action: none;
  --size: .75em;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
}

.PinturaShapeManipulator:not([data-control="edge"]) {
  margin-left: calc(var(--size) * (-.5) );
  margin-top: calc(var(--size) * (-.5) );
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  border-radius: 9999em;
}

.PinturaShapeManipulator:not([data-control="edge"]):after {
  content: "";
  position: absolute;
  inset: -.5em;
}

.PinturaShapeManipulator:not([data-control="edge"])[data-focus-visible]:after {
  border-radius: inherit;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapeManipulator[data-control="point"] {
  background: linear-gradient(90deg, var(--color-secondary-dark), var(--color-secondary));
  z-index: 3;
  box-shadow: 0 0 0 2px #fff, 0 0 4px #000;
}

.PinturaShapeManipulator[data-control="edge"] {
  margin-top: calc(var(--size) * (-.5) );
  height: var(--size);
  width: 1px;
  z-index: 1;
  transform-origin: 0 calc(var(--size) * .5);
}

.PinturaShapeManipulator[data-control="edge"][data-focus-visible] {
  background-color: var(--color-focus-50);
}

.PinturaShapeManipulator[data-control="rotate"] {
  box-shadow: 0 0 0 2px var(--color-secondary-dark), 0 0 4px #000;
  z-index: 2;
  cursor: -webkit-grab;
  cursor: grab;
  background: linear-gradient(90deg, #fff, #ddd);
  border-radius: 9999em;
}

.PinturaDragButton {
  cursor: var(--button-cursor);
  outline: #0000;
}

.PinturaDragButton > * {
  pointer-events: none;
}

.PinturaDragButton {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  transition: filter var(--transition-duration-25) ease-out;
}

.PinturaDragButton[disabled] {
  filter: var(--filter-disabled);
}

/*# sourceMappingURL=index.f483cce5.css.map */
