@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  overflow: hidden;
  position: relative;
}

/* h-full for 404 page */
html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

#not-found {
  background-image: url('./404-background.avif');
}
